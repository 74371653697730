// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Careers',
  nakedPageSlug: 'careers',
  pageAbstract: 'Postings coming soon...',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1>Careers</h1>
    <p>
      If you are interested in a post and think that you qualify for it, please
      send a cover letter and your resume to the email address listed on the
      contact page. We receive many applications every week and may not be able
      to respond to each applicant individually. We usually take a month's time
      to review and shortlist candidates.
    </p>
    <h2>Resume</h2>
    <p>
      We will only process applications which are complete, and which are in a
      standardised format.
    </p>
    <h2>Hiring process</h2>
    <p>
      Cambridge Innovative School receives hundreds of applications for few
      teaching posts every year. We have a very selective process and consider
      each applicant separately. Generally, the applications are strong, with
      many years of teaching experience and a good educational background. We do
      take people fresh out of university every now and then. Once an
      application is short listed, the applicant is then called for a round of
      interviews. Successful applicants are welcomed into the Learning Wings
      family.
    </p>
    <h2>Benefits</h2>
    <p>
      Recognized as an employer of choice, we have an outstanding, cohesive and
      diverse professional staff committed to continuous growth and excellence
      in education. It is our priority to hire, train, stimulate and retain the
      best faculty and staff. Teachers who are well supported are able to offer
      the best education possible and develop successful students. The Cambridge
      International School offers extensive programs to teachers through
      training events as well as through the means of online e-learning modules.
      All Cambridge teachers enjoy free education for their children.
    </p>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
